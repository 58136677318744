import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import Fade from "react-reveal/Fade"
import media from "styled-media-query"
import { isMobile } from "react-device-detect"

import Layout from "../components/core/layout"
import PrimaryButton from "../components/button/primary-button"
import ProjectSlider from "../components/feature/project-slider"
import Contact from "../components/contact"
import PlasticOverlay from "../components/elements/image-plastic-wrapper"
import HomeX from "../components/elements/image-home-X"
import ApproachX from "../components/elements/image-approach-X"
import FixedBackground from "../components/elements/image-background-fixed"
import SEO from "../components/core/seo"
import LogoGrid from "../components/widgets/logoGrid"

import ClientBraven from "../images/clients/graphic-client-braven.png"
import ClientCLF from "../images/clients/graphic-client-clf.png"
import ClientExelon from "../images/clients/graphic-client-exelon.png"
import ClientMIT from "../images/clients/graphic-client-mit.png"
import GoogleTag from "../components/analytics/google-tag"

// Data

const featuredClients = [
  {
    photo: ClientBraven,
    name: "Braven",
    link: "https://bebraven.org/",
  },
  {
    photo: ClientCLF,
    name: "Rihanna Clara Lionel Foundation",
    link: "https://claralionelfoundation.org/",
  },
  {
    photo: ClientExelon,
    name: "Exelon",
    link: "https://www.exeloncorp.com/",
  },
  {
    photo: ClientMIT,
    name: "Massachusetts Institute of Technology",
    link: "https://web.mit.edu/",
  },
]

// Animations

const growWidth = keyframes`
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
`

const moveIn = keyframes`
  from {
    margin-right:-200px;
  }

  to {
    margin-right:-20px;
  }
`

// Layout Styling

const IndexContainer = styled.div`
  overflow-x: hidden;
  width: 100%;
  height: 100%;
`

const HomeHorizontalRule = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 150px;
  margin-bottom: 50px;
  background-color: #535663;
  transform-origin: center;
  animation: ${growWidth} 2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
`

const SectionHorizontalRule = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 85px;
  margin-bottom: 80px;
  background-color: #535663;
`

const VerticalRule = styled.div`
  height: 83px;
  width: 2px;
  margin-right: 74px;
  background-color: #535663;

  ${media.lessThan("medium")`
    margin-right: 32px;
    width: 4px;
  `}
`

// Home / Who Section Styling

const WhoSection = styled.div`
  display: flex;
`

const PrimaryContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  pointer-events: none;

  ${media.lessThan("medium")`
    flex-direction: column-reverse;
  `}
`

const HomeRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-grow: 1;
  height: 650px;

  ${media.lessThan("medium")`
    flex-direction: row;
    height: 500px;
  `}
`

const BannerYear = styled.div`
  position: absolute;
  font-family: "Roobert Bold";
  text-align: right;
  font-size: 100px;
  line-height: 5rem;
  margin-top: 300px;
  margin-right: -20px;
  right: 0;
  animation-delay: 4s;
  animation: ${moveIn} 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  pointer-events: none;

  ${media.lessThan("1220px")`
    font-size: 70px;
    line-height: 3rem;
    margin-top: 360px;
  `}

  ${media.lessThan("medium")`
    font-size: 36px;
    line-height: 2rem;
    margin-right: -10px;
    margin-top: 266px;
  `}
`

const PrimaryHeadline = styled.div`
  font-family: "Roobert SemiBold";
  font-size: 186px;
  font-kerning: 0.1rem;
  line-height: 7.8rem;
  writing-mode: vertical-rl;
  margin-bottom: 0px;
  pointer-events: none;
  min-height: 600px;

  ${media.lessThan("1220px")`
    font-size: 160px;
    line-height: 6.8rem;
  `}

  ${media.lessThan("medium")`
    font-size: 57px;
    line-height: 2.8rem;
    min-height: 50px;
  `}
`

const PrimaryHeadlineSub = styled(PrimaryHeadline)`
  ${media.lessThan("medium")`
    margin-top: 8px;
    font-size: 57px;
    line-height: 2.8rem;
  `}
`

const PrimaryDescription = styled.div`
  text-align: right;
  font-family: "Roobert SemiBold";
  font-size: 36px;
  line-height: 1.1;
  pointer-events: none;
  padding-right: 80px;

  ${media.lessThan("1220px")`
    font-size: 32px;
    padding-right: 0px;
  `}

  ${media.lessThan("medium")`
    font-size: 20px;
    padding-right: 0px;
  `}
`

const HomeHeroLockup = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-end;
`

const SmallRule = styled.div`
  height: 1px;
  width: 232px;
  margin-top: 90px;
  margin-right: 15px;
  margin-bottom: 50px;
  background-color: #535663;
  transform-origin: right;
  animation: ${growWidth} 2s cubic-bezier(0.785, 0.135, 0.15, 0.86);

  ${media.lessThan("medium")`
    margin-top: 90px;
    width: 50vw;
    margin-right: 30px;
    transform-origin: left;
  `}
`

const ContactWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-right: 55px;
  width: 250px;
  padding-top: 0px;

  ${media.lessThan("medium")`
    flex-direction: row;
    width: 200px;
  `}
`

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`

const BoldContact = styled.div`
  font-family: "Nitti Bold";
  margin-left: 10px;
  pointer-events: none;

  ${media.lessThan("medium")`
    font-size: 10px;
  `}
`

const ContactUs = styled.div`
  margin-left: 10px;
  padding-bottom: 10px;

  ${media.lessThan("medium")`
    font-size: 10px;
  `}
`

const ContactProject = styled.div`
  line-height: 0.8rem;
`

// What Section Styling

const WhatSection = styled.div`
  display: flex;

  ${media.lessThan("medium")`
    flex-direction: column;
  `}
`

const WhatLeftContainer = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;

  ${media.lessThan("medium")`
    width: 100%;
  `}
`

const WhatRightContainer = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-items: flex-start;

  ${media.lessThan("medium")`
    width: 100%;
  `}
`

const WhatApproachContainer = styled.div`
  display: flex;

  ${media.lessThan("medium")`
    padding-left: 20vw;
  `}
`

const BasedText = styled.div`
  font-family: "Nitti Bold";
  margin-bottom: 18px;
  pointer-events: none;
`

const ClientsText = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
`

const InnovationText = styled.div`
  font-family: "Roobert SemiBold", sans-serif;
  margin-bottom: 1.45rem;
  line-height: 2rem;
  font-size: 32px;
  max-width: 525px;
  pointer-events: none;

  ${media.lessThan("medium")`
    font-size: 21px;
    line-height: 1.4rem;
    margin-bottom: 80px;
  `}
`

const ApproachText = styled.div`
  font-family: "Roobert SemiBold", sans-serif;
  margin-bottom: 1.45rem;
  line-height: 1.6rem;
  font-size: 28px;
  max-width: 450px;
  pointer-events: none;

  ${media.lessThan("medium")`
    font-size: 15px;
    line-height: 1rem;
  `}
`

const LargeElement = styled.div`
  font-family: "Roobert Bold";
  font-size: 2.25rem;
  line-height: 1.1;
  font-size: 186px;
  height: 70px;
  line-height: 124px;
  margin-bottom: 116px;
  pointer-events: none;
`

const LargeArrow = styled.div`
  font-family: "Roobert Bold";
  font-size: 2.25rem;
  line-height: 1.1;
  font-size: 200px;
  writing-mode: tb-rl;
  margin-top: 92px;
  align-self: flex-start;
  margin-left: 25px;
  pointer-events: none;

  ${media.lessThan("medium")`
    align-self: center;
  `}
`

// What we do Section Styling

const PhaseSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${media.lessThan("medium")`
    display: relative;
    flex-direction: column;
    padding-bottom: 20vh;
  `}
`

const PhaseTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${media.greaterThan("medium")`
    min-height: 30rem;
  `}

  ${media.lessThan("medium")`
    align-items: center;
    height: 400px !important;
    width: 100%;
  `}
`

const PhaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 15vw;
  word-wrap: normal;

  ${media.lessThan("medium")`
    width: 45vw;
    align-items: center;
    margin-bottom: -20vh;
  `}
`

const PhaseContainerOdd = styled(PhaseContainer)`
  ${media.lessThan("medium")`
    align-self: flex-end;
  `}
`

const PhaseNumber = styled.div`
  font-family: "Roobert SemiBold", sans-serif;
  margin-bottom: 1.45rem;
  font-size: 0.8rem;
  margin-left: 45px;
  pointer-events: none;

  ${media.lessThan("medium")`
    margin-left: auto;
    margin-right: auto;
  `}
`

const PhaseVerticalRule = styled.div`
  flex-grow: 1;
  width: 1px;
  margin-bottom: 45px;
  margin-left: 55px;
  background-color: #535663;

  ${media.lessThan("medium")`
    margin-left: auto;
    margin-right: auto;
  `}
`

const PhaseName = styled.div`
  font-family: "Roobert SemiBold", sans-serif;
  margin-bottom: 1.45rem;
  font-size: 6.25rem;
  font-kerning: 0.1rem;
  line-height: 7.8rem;
  margin-left: -35px;
  color: #b8b8b8;
  writing-mode: tb-rl;
  pointer-events: none;
  transform: rotate(-180deg);

  ${media.lessThan("medium")`
    font-size: 66px;
  `}
`

const PhaseDescription = styled.div`
  flex-grow: 1;
  color: #9c9eab;
  line-height: 0.9rem;
  margin-top: 0.2rem;
  margin-bottom: 1.9rem;
  max-width: 220px;
  pointer-events: none;

  ${media.lessThan("1220px")`
    font-size: 12px;
  `}

  ${media.lessThan("medium")`
    font-size: 11px;
    max-width: 100%;
  `}
`

const PhaseDisciplineContainer = styled.ul`
  ${media.greaterThan("medium")`
    height: auto;
    max-height: 0px;
    overflow-y: hidden;
    overflow-x: visible;
    width: 260px;
    padding-left: 40px;
    margin-left: -20px;
    transition: max-height 0.05s ease-out, opacity 0.5s linear;
  `}
`

const PhaseDisciplines = styled.li`
  font-size: 0.75rem;
  color: #fff;
  line-height: 0.87rem;
  white-space: nowrap;

  ${media.lessThan("1220px")`
    font-size: 12px;
  `}

  ${media.lessThan("medium")`
    font-size: 11px;
  `}
`

const PhaseSpacer = styled.div`
  flex-grow: 1;
  min-width: 60px;
`

// Featured Projects styling

const SectionHeader = styled.div`
  color: #b8b8b8;
  font-family: "Roobert SemiBold";
  font-size: 5.5rem;
  line-height: 1.1;
  margin-bottom: 1.45rem;
  pointer-events: none;

  ${media.lessThan("medium")`
    font-size: 43px;
  `}
`

const ProjectHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  ${media.lessThan("medium")`
    flex-direction: column;
    align-items: flex-start;
  `}
`

// Variables

const Index = () => {
  // State + Variables
  const [currentPhase, setCurrentPhase] = useState("")
  const currentYearString = new Date().getFullYear().toString()

  // Methods

  const phaseHover = name => {
    setCurrentPhase(name)
  }

  // Render

  return (
    <IndexContainer>
      <FixedBackground />
      <PlasticOverlay />
      <ApproachX />
      <Layout activeSection="home">
        <BannerYear>
          <div>{currentYearString.substr(0, 2)}</div>
          <div>/{currentYearString.substr(2, 2)}</div>
        </BannerYear>
        <SEO title="Futurehaus: Chicago Design &amp; Technology Studio" />
        <WhoSection>
          <div>
            <PrimaryContainer>
              <PrimaryHeadlineSub>
                <Fade bottom cascade duration={1250} distance="25px">
                  <div>Studio</div>
                </Fade>
              </PrimaryHeadlineSub>
              <PrimaryHeadline>
                <Fade top cascade duration={1250} distance="25px">
                  <div>Design</div>
                </Fade>
                <Fade duration={2000}>
                  <div>&amp; Tech</div>
                </Fade>
              </PrimaryHeadline>
            </PrimaryContainer>
          </div>
          <HomeRightContainer>
            <HomeHeroLockup>
              <PrimaryDescription>
                <HomeX />
                <Fade bottom cascade duration={1000} distance="20px">
                  <div>We create compelling</div>
                </Fade>
                <Fade bottom cascade duration={1000} distance="20px">
                  <div>apps &amp; websites driven by</div>
                </Fade>
                <Fade bottom cascade duration={1000} distance="20px">
                  <div>innovative strategy</div>
                </Fade>
              </PrimaryDescription>
              <SmallRule />
              <Fade>
                <ContactWrapper>
                  <ContactContainer>
                    <BoldContact>Contact us</BoldContact>
                    <ContactUs>
                      <ContactProject>
                        Have a project in mind?
                        <br />
                        Call or email us:
                      </ContactProject>
                      <br />
                      Tel: <a href="tel:312-985-6537">312.985.6537</a>
                    </ContactUs>
                    <PrimaryButton link="/#contact" tabindex="0">
                      Get in touch
                    </PrimaryButton>
                  </ContactContainer>
                </ContactWrapper>
              </Fade>
            </HomeHeroLockup>
          </HomeRightContainer>
        </WhoSection>
        <Fade>
          <HomeHorizontalRule id="featured-clients" />
        </Fade>
        <div>
          <ClientsText>
            <Fade left distance="20px">
              Featured Clients
            </Fade>
          </ClientsText>
          <Fade>
            <LogoGrid
              partners={featuredClients}
              basis={25}
              width={75}
              marginTop={20}
              marginBottom={-100}
              marginMobileTop={40}
              marginMobileBottom={40}
              containerMarginMobileBottom={-100}
            />
          </Fade>
        </div>
        <Fade>
          <HomeHorizontalRule id="what" />
        </Fade>
        <div>
          <WhatSection>
            <WhatLeftContainer>
              <LargeElement>*</LargeElement>
              <BasedText>
                <Fade left distance="20px">
                  A Chicago-based app & website studio
                </Fade>
              </BasedText>
              <Fade left distance="20px">
                <InnovationText>
                  Integrating design and technology to create adaptable digital
                  solutions
                </InnovationText>
              </Fade>
            </WhatLeftContainer>
            <WhatRightContainer>
              <WhatApproachContainer>
                <VerticalRule />
                <Fade right distance="20px">
                  <ApproachText>
                    Our unique, strategic approach first proves the value of
                    your idea, <br />
                    ensuring it makes an impact
                  </ApproachText>
                </Fade>
              </WhatApproachContainer>
              <LargeArrow>→</LargeArrow>
            </WhatRightContainer>
          </WhatSection>
          <SectionHorizontalRule />
          <PhaseSection>
            <PhaseContainer
              className="phaseContainer"
              onFocus={phaseHover}
              onMouseOver={() => {
                phaseHover("learn")
              }}
              onBlur={phaseHover}
              onMouseOut={phaseHover}
            >
              <PhaseTop>
                <PhaseNumber>01</PhaseNumber>
                <PhaseVerticalRule />
                <PhaseName
                  className={currentPhase === "learn" ? "currentPhase" : ""}
                >
                  Learn
                </PhaseName>
              </PhaseTop>
              <PhaseDescription>
                Discover and define your goals. Map out a strategy to move
                forward.
              </PhaseDescription>
              <PhaseDisciplineContainer
                className={currentPhase === "learn" ? "openedContainer" : ""}
                style={{
                  transitionDelay:
                    currentPhase === "learn" ? "0s, 0.25s" : ".25s, .0s",
                  opacity: currentPhase === "learn" || isMobile ? "1" : "0",
                }}
              >
                <PhaseDisciplines className="discipline-bullet">
                  Business Model
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet">
                  Branding &amp; Positioning
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet">
                  Product &amp; Market
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet">
                  Technology &amp; Innovation
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet">
                  Security &amp; Privacy
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet">
                  Change Management
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet">
                  Fundraising Strategy
                </PhaseDisciplines>
              </PhaseDisciplineContainer>
            </PhaseContainer>
            <PhaseSpacer />
            <PhaseContainerOdd
              className="phaseContainer"
              onFocus={phaseHover}
              onMouseOver={() => {
                phaseHover("plan")
              }}
              onBlur={phaseHover}
              onMouseOut={phaseHover}
            >
              <PhaseTop>
                <PhaseNumber>02</PhaseNumber>
                <PhaseVerticalRule />
                <PhaseName
                  id="DefaultPhase"
                  className={currentPhase === "plan" ? "currentPhase" : ""}
                >
                  Plan
                </PhaseName>
              </PhaseTop>
              <PhaseDescription>
                Explore your idea from every angle and turn insights into
                prototypes.
              </PhaseDescription>
              <PhaseDisciplineContainer
                className={currentPhase === "plan" ? "openedContainer" : ""}
                style={{
                  transitionDelay:
                    currentPhase === "plan" ? "0s, 0.25s" : ".25s, .0s",
                  opacity: currentPhase === "plan" || isMobile ? "1" : "0",
                }}
              >
                <PhaseDisciplines className="discipline-bullet">
                  Design Thinking
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet">
                  Human-Centered Design
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet">
                  Information Architecture
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet">
                  Experience Design
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet">
                  Visual Design
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet">
                  User Research
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet">
                  Prototyping
                </PhaseDisciplines>
              </PhaseDisciplineContainer>
            </PhaseContainerOdd>
            <PhaseSpacer />
            <PhaseContainer
              className="phaseContainer"
              onFocus={phaseHover}
              onMouseOver={() => {
                phaseHover("create")
              }}
              onBlur={phaseHover}
              onMouseOut={phaseHover}
            >
              <PhaseTop>
                <PhaseNumber>03</PhaseNumber>
                <PhaseVerticalRule />
                <PhaseName
                  className={currentPhase === "create" ? "currentPhase" : ""}
                >
                  Create
                </PhaseName>
              </PhaseTop>
              <PhaseDescription>
                Dive into development to craft the best version of your idea.
              </PhaseDescription>
              <PhaseDisciplineContainer
                className={currentPhase === "create" ? "openedContainer" : ""}
                style={{
                  transitionDelay:
                    currentPhase === "create" ? "0s, 0.25s" : ".25s, .0s",
                  opacity: currentPhase === "create" || isMobile ? "1" : "0",
                }}
              >
                <PhaseDisciplines className="discipline-bullet">
                  Design Systems
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet">
                  Mobile and Web Apps
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet">
                  Machine Learning
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet">
                  Software as a Service
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet">
                  Continuous Delivery
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet">
                  Test Driven Development
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet">
                  Dev Ops
                </PhaseDisciplines>
              </PhaseDisciplineContainer>
            </PhaseContainer>
            <PhaseSpacer />
            <PhaseContainerOdd
              className="phaseContainer"
              onFocus={phaseHover}
              onMouseOver={() => {
                phaseHover("release")
              }}
              onBlur={phaseHover}
              onMouseOut={phaseHover}
            >
              <PhaseTop>
                <PhaseNumber>04</PhaseNumber>
                <PhaseVerticalRule />
                <PhaseName
                  className={currentPhase === "release" ? "currentPhase" : ""}
                >
                  Release
                </PhaseName>
              </PhaseTop>
              <PhaseDescription>
                Bring your vision to life and give you tools to measure success.
              </PhaseDescription>
              <PhaseDisciplineContainer
                className={currentPhase === "release" ? "openedContainer" : ""}
                style={{
                  transitionDelay:
                    currentPhase === "release" ? "0s, 0.25s" : ".25s, .0s",
                  opacity: currentPhase === "release" || isMobile ? "1" : "0",
                }}
              >
                <PhaseDisciplines className="discipline-bullet">
                  Product Release
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet">
                  Advertising &amp; Social Media
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet">
                  Metrics &amp; Monitoring
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet">
                  Maintenance
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet">
                  Implementation Services
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet">
                  Engagement Campaigns
                </PhaseDisciplines>
                <PhaseDisciplines className="discipline-bullet">
                  Training &amp; Adoption
                </PhaseDisciplines>
              </PhaseDisciplineContainer>
            </PhaseContainerOdd>
          </PhaseSection>
        </div>
        <SectionHorizontalRule
          id="work"
          style={{
            marginTop: "0px",
          }}
        />
        <div>
          {/* <ProjectHeader>
            <Fade bottom cascade duration={1250} distance="25px">
              <SectionHeader>
                Featured
                <br />
                Projects
              </SectionHeader>
            </Fade>
          </ProjectHeader>
           <ProjectSlider /> */}
          <Contact>
            Have a project?
            <br />
            Reach out
          </Contact>
        </div>
      </Layout>
    </IndexContainer>
  )
}

export default Index
