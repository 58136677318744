import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { isAndroid, isMobile } from "react-device-detect"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import linkBackgroundImage from "../../images/graphic-link-bg-bright.png"

import ProjectX from "../elements/image-project-X"
import FeatureYU from "../../images/graphic-feature-yearup.png"
import FeatureJG from "../../images/graphic-feature-jugl.png"
import FeatureCL from "../../images/graphic-feature-cleancio.png"
import FeatureWE from "../../images/graphic-feature-worldwe.png"
import FeatureEX from "../../images/graphic-feature-exelon.png"

// Carousel

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

// Components

const ProjectCounter = styled.div`
  position: absolute;
  text-align: right;
  font-family: "Roobert SemiBold";
  font-size: 2rem;
  line-height: 1.8rem;
  margin-top: -125px;
  margin-bottom: 2.2rem;
  flex-grow: 1;
  right: 165px;
  pointer-events: none;

  ${media.lessThan("medium")`
    margin-top: ${isAndroid ? -134 : -130}px
    margin-right: 30px;
    margin-bottom: 0px;
    font-size: 18px;
    line-height: 1rem;
    right: 0px;
  `}
`

const SliderComponentContainer = styled.div`
  margin-top: 24px;
`

const SliderContainer = styled(Carousel)`
  width: 100vw;
  margin-left: -30px;

  ${media.greaterThan("medium")`
    width: 100vw;
    left: -10vw;
    margin-left: 0px;
    padding-left: 10vw;
  `}

  ${media.greaterThan("large")`
    width: 110vw;
    left: -20vw;
    margin-left: 0px;
    padding-left: 20vw;
  `}

  ${media.lessThan("400px")`
    margin-left: -50px;
  `}
`

const CardContainer = styled.div`
  width: 691px;
  height: 605px;
  margin-top: 155px;

  ${media.lessThan("medium")`
    margin-top: 80px;
    margin-left: 50px;
    width: 345px;
    height: 305px;
  `}
`

const CaseImage = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  z-index: 11;
  top: 0px;
  margin-top: -160px;

  ${media.lessThan("medium")`
    margin-top: -80px;
  `}
`

const CaseFeatureImage = styled.img`
  width: 691px;
  height: 697px;
  pointer-events: none;

  ${media.lessThan("medium")`
    width: 345px;
    height: 348px;
    margin-left: -46px;
  `}
`

const CardBackground = styled.div`
  position: absolute;
  width: 600px;
  height: 385px;
  margin-left: 50px;
  background-color: #d2d2d2;
  transform: rotateZ(45deg);
  z-index: 10;
  margin-top: 160px;

  ${media.lessThan("medium")`
    width: 300px;
    height: 192px;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
  `}
`

const CardDescription = styled.div`
  margin-top: 80px;
  margin-left: 145px;
  max-width: 425px;

  ${media.lessThan("medium")`
    margin-top: 60px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
  `}
`

const CaseContainer = styled.div`
  position: relative;
  min-height: 550px;
  width: 100vw;
  margin-right: 166px;

  ${media.lessThan("medium")`
    min-height: 450px;
  `}
`

const CaseHeader = styled.div`
  font-family: "Roobert SemiBold";
  font-size: 3.125rem;
  color: #b8b8b8;
  margin-bottom: 40px;
  pointer-events: none;

  ${media.lessThan("medium")`
    font-size: 26px;
    margin-bottom: 25px;
  `}
`

const CaseDescription = styled.div`
  line-height: 0.9rem;
  color: #9c9eab;
  margin-bottom: 32px;
  height: 30px;
  pointer-events: none;

  ${media.lessThan("medium")`
    width: 70vw;
    font-size: 10px;
  `}
`

const LargeX = styled(ProjectX)`
  ${media.lessThan("medium")`
    margin-left: auto;
    margin-right: auto;
  `}
`

// Controls

const SliderControls = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  margin-top: -750px;
  margin-left: 790px;

  ${media.lessThan("large")`
    margin-left: 0px;
    margin-right: 30px;
    right: 0px;
  `}

  ${media.lessThan("medium")`
    margin-top: -590px;
    margin-left: 0px;
    margin-right: 30px;
    right: 0px;
  `}
`

const ControlSpacer = styled.div`
  width: 10px;
`

const SliderButton = styled.button`
  display: flex;
  width: 55px;
  height: 55px;
  border-width: 0px;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;

  ${media.lessThan("medium")`
    width: 40px;
    height: 40px;
    background-color: #7c365f;
  `}
`

const ButtonArrow = styled.div`
  font-size: 31px;
  font-family: "Roobert SemiBold";
  line-height: 1.1;
  color: #b8b8b8;
  margin: 8px auto auto auto;

  /* Firefox */
  @-moz-document url-prefix() {
    & {
      margin-top: 6px;
    }
  }

  /* Microsoft IE10 and above */
  @media all and (-ms-high-contrast: none) {
    & {
      margin-top: 4px;
    }
  }

  /* Microsoft Edge */
  @supports (-ms-ime-align: auto) {
    & {
      margin-top: 4px;
    }
  }

  ${media.lessThan("medium")`
    font-size: 18px;
    margin: auto auto 4px auto;
  `}
`

const ProjectButton = styled.button`
  padding: 0px 22px 0px 18px;
  height: 31px;
  font-size: 0.75rem;
  color: #ffffff;
  vertical-align: middle;
  background-color: transparent;
  border: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(${linkBackgroundImage});
  text-align: left;
  min-width: 150px;

  pointer-events: none;

  ${media.lessThan("medium")`
      font-size: 10px;
      width: 120px;
      min-width: 80px;
  `}
`

// Custom Arrows

let totalSlides = 5

const CustomButtonGroupAsArrows = ({ next, previous, carouselState }) => {
  const { totalItems } = carouselState
  totalSlides = totalItems
  return (
    <SliderControls>
      <SliderButton className="sliderButton" onClick={previous}>
        <ButtonArrow>←</ButtonArrow>
      </SliderButton>
      <ControlSpacer />
      <SliderButton className="sliderButton" onClick={next}>
        <ButtonArrow>→</ButtonArrow>
      </SliderButton>
    </SliderControls>
  )
}

export default class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visibleSlide: 0,
      totalNumberSlides: totalSlides,
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(nextSlide, { currentSlide, onMove }) {
    this.setState({ visibleSlide: nextSlide })
  }

  render() {
    return (
      <SliderComponentContainer>
        <ProjectCounter>
          <div>0{this.state.visibleSlide + 1}</div>
          <div>/0{totalSlides}</div>
        </ProjectCounter>
        <LargeX />
        <SliderContainer
          arrows={false}
          responsive={responsive}
          swipeable={isMobile}
          customButtonGroup={<CustomButtonGroupAsArrows />}
          keyBoardControl
          beforeChange={this.handleChange}
          renderButtonGroupOutside
        >
          <AniLink
            cover
            direction="left"
            to="/work/cleancio"
            bg="#222533"
            className="caseLink"
          >
            <CaseContainer
              className={
                this.state.visibleSlide !== 1 ? "sliderOffset" : "sliderNormal"
              }
            >
              <CardContainer>
                <CardBackground />
                <CaseImage>
                  <CaseFeatureImage src={FeatureCL} />
                </CaseImage>
              </CardContainer>
              <CardDescription
                className={
                  this.state.visibleSlide !== 1 ? "sliderHide" : "sliderReveal"
                }
              >
                <CaseHeader>Cleancio</CaseHeader>
                <CaseDescription>
                  Enable a vacation rental cleaning service to scale staff
                  efficiency.
                </CaseDescription>
                <ProjectButton className="primaryButton">
                  View project
                </ProjectButton>
              </CardDescription>
            </CaseContainer>
          </AniLink>
          <AniLink
            cover
            direction="left"
            to="/work/yearup"
            bg="#222533"
            className="caseLink"
          >
            <CaseContainer
              className={
                this.state.visibleSlide !== 2 ? "sliderOffset" : "sliderNormal"
              }
            >
              <CardContainer>
                <CardBackground />
                <CaseImage>
                  <CaseFeatureImage src={FeatureYU} />
                </CaseImage>
              </CardContainer>
              <CardDescription
                style={{
                  display: this.state.visibleSlide === 2 ? "block" : "none",
                }}
              >
                <CaseHeader>Year Up</CaseHeader>
                <CaseDescription>
                  Help employees improve communication, foster culture, share
                  best practices, and enhance collaboration.
                </CaseDescription>
                <ProjectButton className="primaryButton">
                  View project
                </ProjectButton>
              </CardDescription>
            </CaseContainer>
          </AniLink>
          <AniLink
            cover
            direction="left"
            to="/work/exelon"
            bg="#222533"
            className="caseLink"
          >
            <CaseContainer
              className={
                this.state.visibleSlide !== 3 ? "sliderOffset" : "sliderNormal"
              }
            >
              <CardContainer>
                <CardBackground />
                <CaseImage>
                  <CaseFeatureImage src={FeatureEX} />
                </CaseImage>
              </CardContainer>
              <CardDescription
                style={{
                  display: this.state.visibleSlide === 3 ? "block" : "none",
                }}
              >
                <CaseHeader>Exelon</CaseHeader>
                <CaseDescription>
                  Empower consumers to minimize their individual carbon
                  footprint.
                </CaseDescription>
                <ProjectButton className="primaryButton">
                  View project
                </ProjectButton>
              </CardDescription>
            </CaseContainer>
          </AniLink>
          <AniLink
            cover
            direction="left"
            to="/work/jugl"
            bg="#222533"
            className="caseLink"
          >
            <CaseContainer
              className={
                this.state.visibleSlide !== 4 ? "sliderOffset" : "sliderNormal"
              }
            >
              <CardContainer>
                <CardBackground />
                <CaseImage>
                  <CaseFeatureImage src={FeatureJG} />
                </CaseImage>
              </CardContainer>
              <CardDescription
                style={{
                  display: this.state.visibleSlide === 4 ? "block" : "none",
                }}
              >
                <CaseHeader>Jugl</CaseHeader>
                <CaseDescription>
                  Change the way families coordinate events and logistics.
                </CaseDescription>
                <ProjectButton className="primaryButton">
                  View project
                </ProjectButton>
              </CardDescription>
            </CaseContainer>
          </AniLink>
          <AniLink
            cover
            direction="left"
            to="/work/worldwe"
            bg="#222533"
            className="caseLink"
          >
            <CaseContainer
              className={
                this.state.visibleSlide !== 0 ? "sliderOffset" : "sliderNormal"
              }
            >
              <CardContainer>
                <CardBackground />
                <CaseImage>
                  <CaseFeatureImage src={FeatureWE} />
                </CaseImage>
              </CardContainer>
              <CardDescription
                style={{
                  display: this.state.visibleSlide === 0 ? "block" : "none",
                }}
              >
                <CaseHeader>WorldWE</CaseHeader>
                <CaseDescription>
                  Create a platform to shift the conversation <br />
                  about human trafficking and sexual exploitation.
                </CaseDescription>
                <ProjectButton className="primaryButton">
                  View project
                </ProjectButton>
              </CardDescription>
            </CaseContainer>
          </AniLink>
        </SliderContainer>
      </SliderComponentContainer>
    )
  }
}
