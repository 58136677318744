import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import media from "styled-media-query"
import useMousePosition from "../hooks/mousePosition"
import useWindowSize from "../hooks/windowSize"

const StyledImage = styled(Img)`
  width: 346px;
  height: 357px;
  margin-top: 1950px;
  transition: transform 1s cubic-bezier(0.22, 1, 0.36, 1);
  transform: translate(21vw, 0vw);
  pointer-events: none;

  ${media.lessThan("medium")`
      width: 173px;
      height: 178px;
      margin-top: 2180px;
      transform: translate(8vw, 0vw);
    `}
`

let newPositionSet
let approachReturned = false

const ApproachX = () => {
  const [positionX, setPositionX] = useState()
  const windowSize = useWindowSize()
  const position = useMousePosition()
  const data = useStaticQuery(graphql`
    query {
      approachX: file(relativePath: { eq: "graphic-target-small.png" }) {
        childImageSharp {
          fluid(maxWidth: 346, maxHeight: 357) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  clearTimeout(newPositionSet)
  newPositionSet = setTimeout(() => {
    if (position.pageY > 1660 && position.pageY < 2500) {
      setPositionX(position.x - 173)
      approachReturned = false
    } else if (!approachReturned) {
      if (typeof document !== "undefined") {
        const coords = document
          .getElementById("DefaultPhase")
          .getBoundingClientRect()
        setPositionX(coords.x - 90)
        approachReturned = true
      }
    }
  }, 3)

  return (
    <StyledImage
      fluid={data.approachX.childImageSharp.fluid}
      style={{
        position: "absolute",
        transform: `translate(${
          windowSize.width < 600 ? "8vw" : `${positionX}px`
        }, 0vw) perspective(10px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)`,
      }}
      alt="Pinkish purple X shape in the background. Follows mouse movement."
    />
  )
}

export default ApproachX
