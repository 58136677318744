import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import media from "styled-media-query"
import Fade from "react-reveal/Fade"

const StyledImage = styled(Img)`
  opacity: 1;
  z-index: 2000;
  width: 100vw;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
  max-width: 1151px;
  height: 986px;
  pointer-events: none;

  ${media.lessThan("medium")`
    margin-top: -40px;
    margin-left: -20px;
    width: 80vw;
    height: 550px;
  `}
`

const PlasticWrapper = () => {
  const data = useStaticQuery(graphql`
    query {
      plasticWrapper: file(relativePath: { eq: "graphic-plastic-a.png" }) {
        childImageSharp {
          fluid(maxWidth: 1151, maxHeight: 986) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <StyledImage
      fluid={data.plasticWrapper.childImageSharp.fluid}
      draggable={false}
      style={{
        position: "absolute",
        zIndex: "2000",
      }}
      alt="Larger plastic shape overlapping Design and Tech Studio, as if a new product is being unwrapped."
    />
  )
}

export default PlasticWrapper
