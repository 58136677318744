import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import media from "styled-media-query"

const StyledImage = styled(Img)`
  width: 346px;
  height: 357px;
  right: 28vw;
  top: 305px;
  pointer-events: none;

  ${media.lessThan("1220px")`
    width: 246px;
    height: 257px;
    right: 23vw;
    top: 365px;
  `}

  ${media.lessThan("medium")`
    width: 173px;
    height: 178px;
    right: 20vw;
    left: auto;
    top: 220px;
  `}
`

const HomeX = () => {
  const data = useStaticQuery(graphql`
    query {
      homeX: file(relativePath: { eq: "graphic-target-small.png" }) {
        childImageSharp {
          fluid(maxWidth: 346, maxHeight: 357) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <StyledImage
      fluid={data.homeX.childImageSharp.fluid}
      style={{
        position: "absolute",
      }}
      alt="Pinkish purple X shape in the background."
    />
  )
}

export default HomeX
